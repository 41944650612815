body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
  "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
  sans-serif;;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  padding-top:0px;
}

.header{
    width: 100%;
    text-align: center;
    margin: 0px;
    padding: 10px;
    border-top: 10px solid #006341;
    border-bottom: 1px solid #006341;
}