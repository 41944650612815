.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #09d3ac;
}


.form-signin
{
  width: 100%;
  max-width: 420px;
  padding: 15px;
  margin: auto;
}
    

.has-error  {

    box-shadow: none;
    color: #dd4b39;
}


.has-error input  {

  border: 2px solid #dd4b39;
}

.has-error select  {
  border: 2px solid #dd4b39;
}

.notificationBar--shop {
  background-color: rgba(0,99,65,1);
  margin: 0;
    padding: 0;
    border: 0;
    font: inherit;
    display: block;
    min-height: 10px;
}

input-group {
  position: relative;
  display: flex;
  border-collapse: separate;
  box-sizing: border-box;
};

input-group .input-group-addon {
  border-radius: 0;
  border-color: #d2d6de;
  background-color: #fff;
};


.list{
  height: 100%;
  padding-top: 75px;
}

.list-row{
  display: flex; 
  flex-direction: row; 
  flex-grow: 1; 
  align-items: center;
  padding: 16px 0px;
  
}

.seperator{
  border-bottom: 1px solid #dee3e7;
}

.thank-you{
  position: fixed;
  text-align: center;
  top: 20%;
  width: 100%;
  height: 100%;
  font-family: Lato;
  font-weight: 400;
  padding: 20px;
  letter-spacing: .1rem;
  }

/* 
  input {
    position: relative;
    width: 150px; height: 20px;
    color: white;
   
}

input:before {
    position: absolute;
    top: 6px; left: 10px;
    content: attr(data-date);
    display: inline-block;
    color: black;
    font-family: "Lato";
    letter-spacing: .1rem;
    font-size:14px;
}

input::-webkit-datetime-edit, input::-webkit-inner-spin-button, input::-webkit-clear-button {
    display: none;
}

input::-webkit-calendar-picker-indicator {
    position: absolute;
    top: 5px;
    right: 5px;
    color: black;
    opacity: 1;
} */